<template>
  <div class="StoriesPostCard third-font" v-if="numSlides || !allStoriesLoaded" :stories="numSlides">
    <div class="title third-font">
      <span v-if="$creator && !isAdminCreator()" strong>{{ $locale["my_stories"] }}</span>
      <span v-else strong>{{ $locale["stories"] }}</span>
      <iconic v-if="!numSlides" dcolor size="30px" name="gspinner" />
      <ArrowsControl size="30px" v-else-if="!$isMobile && showControls" @next="$refs.slider.next()" @prev="$refs.slider.prev()" />
    </div>
    <div class="content" v-if="numSlides">
      <Carrousel :settings="settings" class="storiesCarrousel" ref="slider">
        <div :class="`SlideStory`" v-for="(post, index) in cardStories" :key="`story-${index}${post._id}`">
          <FeedPost :class="`singleStory ${$isMobile && numSlides > 2 ? '__mobile' : ''}`" :post="post" :index="index" :settings="postSettings" />
          <div class="UserLabel" v-if="isAdminCreator() || !$creator">
            <UserAvatar :user="post.user" size="24px" />
            <div class="UserLabelName">
              <div class="UserLabelText">@{{ post.user.user }}</div>
            </div>
          </div>
        </div>
      </Carrousel>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  data: function() {
    return {
      limit: 12,
      error: null,
      postSettings: {
        swipe: false,
      },
    };
  },
  methods: {
    getStories: async function() {
      this.page = this.post.currentPage;

      if (this.cardStories) {
        return;
      }

      this.endpoint = `posts/stories-card`;

      if (this.$creator && !this.isAdminCreator()) {
        this.endpoint = `posts`;
      }

      try {
        this.query = `page=${this.page}`;
        this.query += `&limit=${this.limit}`;
        this.query += `&type=story`;
        this.query += `&creator=${this.$creator ? this.$creator : ""}`;
        this.query += `&nostatics=true`; // no statics posts
        this.req = await this.$api.get(`${process.env.VUE_APP_API_HOST}${this.endpoint}?${this.query}`);
        this.$store.state.posts.stories = {
          ...this.$store.state.posts.stories,
          [this.page]: this.req.data.posts,
        };
        this.$store.state.posts.allStoriesLoaded = !this.req.data.posts.length || this.req.data.posts.length < this.limit;
      } catch (error) {
        this.error = error;
        console.error(error);
      }

      return;
    },
  },
  beforeMount: async function() {
    await this.getStories();
  },
  computed: {
    cardStories: function() {
      return this.$store.state.posts.stories[this.page];
    },
    allStoriesLoaded: function() {
      return this.$store.state.posts.allStoriesLoaded;
    },
    showControls: function() {
      let controls = this.cardStories && this.numSlides > 4;
      return controls;
    },
    slidesToShow: function() {
      let toShow = this.$isMobile ? 3 : 4;
      return toShow;
    },
    slidesToScroll: function() {
      let toScroll = this.$isMobile ? 3 : 4;

      if (this.numSlides <= toScroll) {
        toScroll = 0;
      }

      return toScroll;
    },
    numSlides: function() {
      return this.cardStories && this.cardStories.length;
    },
    hasArrows: function() {
      return this.numSlides > 4;
    },
    settings: function() {
      return {
        arrows: false,
        dots: false,
        mobileFirst: true,
        infinite: false,
        initialSlide: 0,
        slidesToShow: this.slidesToShow,
        slidesToScroll: this.slidesToScroll,
      };
    },
  },
};
</script>

<style lang="scss">
.StoriesPostCard {
  .content {
    padding: 0 0 $mpadding 0;
  }
  .title {
    padding: $mpadding $mpadding $mpadding $mpadding;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $mpadding;
  }
  .singleStory {
    top: 0;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
    .creator-post {
      margin: 0;
      box-shadow: none;
    }
    .creator-post-footer {
      display: none;
    }
    .creator-post-media-wrap {
      height: 200px;
    }
    .p-msg {
      display: none;
    }
    .bubscribe-action {
      font-size: 90%;
    }
    .slick-slide img,
    .slick-slide video {
      object-fit: cover;
    }
    &.__mobile,
    &.__cover {
      .slick-slide img,
      .slick-slide video {
        object-fit: cover;
      }
    }

    .price-discount,
    video {
      display: none;
    }

    .mark-offer {
      font-size: 75%;
      padding: $mpadding/8;
      top: $mpadding/2;
      right: -$mpadding * 4.5;
    }
  }

  .SlideStory {
    position: relative;
    margin: 0 $mpadding/4.3;
    overflow: hidden;
    border-radius: $mpadding/2;
    z-index: 2;
  }

  .storiesCarrousel > .slick-list > .slick-slide:first {
    max-width: 33%;
  }

  .storiesCarrousel > .slick-list > .slick-track {
    display: flex;
  }

  .post-unlock-content,
  .post-subscribe-content {
    padding: $mpadding/2 $mpadding $mpadding $mpadding;
  }

  .UserLabel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: $mpadding/2 $mpadding $mpadding/2 $mpadding;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    color: #fff;
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  .UserLabelName {
    margin-left: $mpadding/2;
    display: grid;
    .UserLabelText {
      font-size: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &[stories="1"],
  &[stories="2"] {
    .storiesCarrousel > .slick-list > .slick-track {
      padding: 0 $mpadding/2;
    }
  }

  .SlideStory .clock-timeleft {
    display: none;
  }

  .SlideStory .post-subscribe-wrap,
  .SlideStory .post-unlock-wrap {
    pointer-events: none;
  }

  .SlideStory .subscribe-action,
  .SlideStory .unlock-action {
    pointer-events: all;
  }

  @include screen($tablet_width) {
    &[stories="3"] {
      .storiesCarrousel > .slick-list > .slick-track {
        padding: 0 $mpadding/2;
      }
    }
  }

  @include screen($pc_width) {
    .storiesCarrousel > .slick-list > .slick-slide:first {
      max-width: 25%;
    }
  }
}
</style>
